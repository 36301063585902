// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benchmark-line-chart-page-js": () => import("./../src/pages/benchmarkLineChartPage.js" /* webpackChunkName: "component---src-pages-benchmark-line-chart-page-js" */),
  "component---src-pages-compliance-trends-page-js": () => import("./../src/pages/complianceTrendsPage.js" /* webpackChunkName: "component---src-pages-compliance-trends-page-js" */),
  "component---src-pages-conversion-rate-doctor-page-js": () => import("./../src/pages/conversionRateDoctorPage.js" /* webpackChunkName: "component---src-pages-conversion-rate-doctor-page-js" */),
  "component---src-pages-conversion-rate-procedure-page-js": () => import("./../src/pages/conversionRateProcedurePage.js" /* webpackChunkName: "component---src-pages-conversion-rate-procedure-page-js" */),
  "component---src-pages-horizontal-bar-chart-page-js": () => import("./../src/pages/horizontalBarChartPage.js" /* webpackChunkName: "component---src-pages-horizontal-bar-chart-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-title-page-page-js": () => import("./../src/pages/titlePagePage.js" /* webpackChunkName: "component---src-pages-title-page-page-js" */)
}

